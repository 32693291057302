import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import { cn } from '../utils/cn';

// const moranga = localFont({ src: '../public/fonts/Moranga/Moranga-Light.woff2' })

const navigation = [{ title: 'Encounters', page: 'encounters', href: '/' }];

export default function Navbar() {
  //const pathname = usePathname()

  const { user, isLoading, logout } = useAuth0();
  const { pathname } = useLocation();
  const isActive = (path: string) => pathname === path;
  console.log("User: ")
  console.log(user)

  return (
    <Disclosure as="nav" className="border-b border-gray-200 bg-white">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <p
                    className={'text-2xl text-gray-700'}
                    style={{ fontFamily: 'Moranga, normal' }}
                  >
                    Joyful Health
                  </p>
                </div>
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                  {navigation.map((item) => (
                    <a
                      key={item.page}
                      href={item.href}
                      className={cn(
                        isActive(item.href)
                          ? 'border-yellow-500 text-gray-900'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium',
                      )}
                      aria-current={isActive(item.href) ? 'page' : undefined}
                    >
                      {item.title}
                    </a>
                  ))}
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <UserCircleIcon className="h-8 w-8 rounded-full stroke-gray-600" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="mx-3 border-b-2 border-gray-200 pb-2">
                        <div className="text-base font-medium text-gray-800">
                          {!isLoading && user ? user.name : 'loading...'}
                        </div>
                        <div className="text-sm font-medium text-gray-500">
                          {!isLoading && user ? user.email : 'loading...'}
                        </div>
                      </div>
                      <Menu.Item>
                        <a href={`https://joyfulhealh.paperform.co?encounter_url=https://joyfulhealth.com${pathname}&email=${user?.email}`} target='_blank' className="block px-4 py-2 text-sm text-gray-700">Leave feedback</a>
                      </Menu.Item>
                      <Menu.Item>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            logout();
                          }}
                          className="block px-4 py-2 text-sm text-gray-700"
                        >
                          Sign out
                        </a>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.page}
                  as="a"
                  href={item.href}
                  className={cn(
                    isActive(item.href)
                      ? 'border-yellow-500 bg-yellow-50 text-yellow-700'
                      : 'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800',
                    'block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
                  )}
                  aria-current={isActive(item.href) ? 'page' : undefined}
                >
                  {item.title}
                </Disclosure.Button>
              ))}
            </div>
            <div className="border-t border-gray-200 pb-3 pt-4">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <UserCircleIcon className="h-9 w-9 rounded-full stroke-gray-600" />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {!isLoading && user ? user.name : 'loading...'}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {!isLoading && user ? user.email : 'loading...'}
                  </div>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                <Disclosure.Button
                  as="a"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    logout();
                  }}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Sign out
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
